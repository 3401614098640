import IQuizResults from '../../interfaces/IQuizResults';
import QuizResult from '../../components/quiz/result/workplace-individual/QuizResult';
import image from '../../assets/images/report/workplace-individual.png';

export class WorkplaceIndividual implements IQuizResults {
  getResultComponent() {
    return QuizResult;
  }

  getResultPageProps({ score = 50, redirectUrl }: any) {
    return {
      subtitle: '',
      title: `Congratulations!`,
      description: `You have completed your Attachment @Work & Well-Being report and your free report is ready to download.`,
      bulletPoints: [
        'Learn more about your attachment patterns at work.',
        'Get insights into your current psychological well-being profile across multiple areas:',
      ],
      subBulletPoints: [
        [],
        ['Self-esteem', 'Workplace Resilience', 'Workplace Burnout', 'Emotion Regulation']
      ],
      color: '#002859',
      backgroundColor: '#255287',
      redirectUrl,
      image,
    };
  }
}
