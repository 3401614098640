import EmotionalDysregulation from "../../components/quiz/survey/intro/EmotionalDysregulation";
import SelfEsteem from "../../components/quiz/survey/intro/SelfEsteem";
import PsychologicalWellBeing from "../../components/quiz/survey/intro/psychological-wellbeing/PsychologicalWellBeing";

const EMOTIONAL_DYSREGULATION = "ders-16";
const SELF_ESTEEM = "self-esteem";
export const PSYCH_WELL_BEING = "psych-well-being";

const components: any = {
  [EMOTIONAL_DYSREGULATION]: EmotionalDysregulation,
  [SELF_ESTEEM]: SelfEsteem,
  [PSYCH_WELL_BEING]: PsychologicalWellBeing,
};

const titles: any = {
  [PSYCH_WELL_BEING]: "Psychological Well-Being",
};

export const getComponentByKey = (key: string): any => {
  return components[key] ?? null;
};

export const getTitleByKey = (key: string): any => {
  return titles[key] ?? null;
};
