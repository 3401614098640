const references: Record<string, Record<string, string>> = {
  "relationship-structures" : { 
   "part-tree-general" : "Fraley, R. C., Heffernan, M. E., Vicary, A. M., & Brumbaugh, C. C. (2011). The experiences in close relationships—Relationship Structures Questionnaire: A method for assessing attachment orientations across relationships. Psychological assessment, 23(3), 615.",
   "part-two-relationship" : "Fraley, R. C., Heffernan, M. E., Vicary, A. M., & Brumbaugh, C. C. (2011). The experiences in close relationships—Relationship Structures Questionnaire: A method for assessing attachment orientations across relationships. Psychological assessment, 23(3), 615.",
   "bfi-10" : "Rammstedt, B. & John, O.P. (2007). Measuring personality in one minute or less: A 10-item short version of  the Big Five Inventory in English and German. Journal of Research in Personality, 41, 203–212.",
   "four-temperaments" : "Fisher, H. E., Island, H. D., Rich, J., Marchalik, D., & Brown, L. L. (2015). Four broad temperament dimensions: description, convergent validation correlations, and comparison with the Big Five. Frontiers in psychology, 6, 1098.",
   "well-being-and-flourishing" : "Diener, E., Wirtz, D., Tov, W., Kim-Prieto, C., Choi. D., Oishi, S., & Biswas-Diener, R. (2009). New measures of well-being: Flourishing and positive and negative feelings. Social Indicators Research, 39, 247-266 Please visit: http://internal.psychology.illinois.edu/~ediener/FS.html.",
   "communication-patterns" : "Heavey, C. L., Larson, B. M., Zumtobel, D. C., & Christensen, A. (1996). The Communication Patterns Questionnaire: The reliability and validity of a constructive communication subscale. Journal of Marriage and the Family, 58(4), 796–800.",
  },
  "self-esteem" : { 
   "survey": "Rosenberg, M. (1965). Society and the adolescent self-image. Princeton, NJ: Princeton University Press.",
  },
  "emotional-regulation" : { 
   "survey":"Bjureberg, J., Ljótsson, B., Tull, M. T., Hedman, E., Sahlin, H., Lundh, L.- G., Bjärehed, J., DiLillo, D., Messman-Moore, T., Gumpert, C. H., & Gratz, K.L. (2016). Development and Validation of a Brief Version of the Difficulties in Emotion Regulation Scale: The DERS-16. Journal of Psychopathology and Behavioral Assessment, 1–13. http://doi.org/10.1007/s10862-015-9514-x.",
  },
  "workplace" : { 
   "burnout" : "Schaufeli, W.B., Bakker, A.B. & Salanova, M. (2006). The measurement of work engagement with a short questionnaire: A cross-national study. Educational and Psychological Measurement, 66, 701-716.",
   "resilience" : "Smith, B. W., Dalen, J., Wiggins, K., Tooley, E., Christopher, P., & Bernard, J. (2008). The brief resilience scale: Assessing the ability to bounce back. International Journal of Behavioral Medicine, 15(3), 194-200. doi:10.1080/10705500802222972.",
   "self-esteem" : "Rosenberg, M. (1965). Society and the adolescent self-image. Princeton, NJ: Princeton University Press.",
   "emotional-regulation" : "Bjureberg, J., Ljótsson, B., Tull, M. T., Hedman, E., Sahlin, H., Lundh, L.- G., Bjärehed, J., DiLillo, D., Messman-Moore, T., Gumpert, C. H., & Gratz, K.L. (2016). Development and Validation of a Brief Version of the Difficulties in Emotion Regulation Scale: The DERS-16. Journal of Psychopathology and Behavioral Assessment, 1–13. http://doi.org/10.1007/s10862-015-9514-x.",
  },
  "maladaptive-schema" : { 
   "survey" : "Young JE. Young Schema Questionnaire Long Form‐3 (YSQ‐L3)  / © 2003, Jeffrey Young, Ph.D., and Gary Brown, Ph.D.",
  },
  "maladaptive-daydreaming" : { 
   "survey" : "Somer, E., Lehrfeld J., Jopp, D.S., & Bigelsen, J. (2016). Development and Validation of the Maladaptive Daydreaming Scale (MDS). Try the Maladaptive Daydreaming Scale.",
  }
};

export const getReferenceBySlug = (step: string, slug: string): string => {
  return references[slug]?.[step] || '';
};
