import Introduction, { Props } from '../Introduction';
import coverImage from '../../../../../assets/images/psychological-well-being.png';
import {getTitleByKey, PSYCH_WELL_BEING} from '../../../../../libs/helpers/surveyIntroductionHelper';

const PsychologicalWellBeing = (props: Props) => {
  return (
    <Introduction
      title={getTitleByKey(PSYCH_WELL_BEING)}
      coverImage={coverImage}
      {...props}
    >
      There are many definitions of psychological well-being because it is a complex and multifaceted construct. 
      It can be defined as the presence of positive (or absence of negative) feelings and generally includes happiness, 
      holistic wellness and life satisfaction.
      <br />
      <br />
      <b>
        This section will ask you questions about how you experience your
        psychological well-being. Some questions will be general and others will
        be work-specific.
      </b>
      <br />
      <br />
    </Introduction>
  );
};

export default PsychologicalWellBeing;
