import React, { useState } from 'react';
import { InputProps } from './types';
import AudioRecorder from '../../../audio-recorder/AudioRecorder';
import TextareaInput from './TextareaInput';

const AudioInput: React.FC<InputProps> = ({
  surveyUserAnswer,
  onChange,
  onDelete,
  disabled,
  updating,
  ...props
}) => {
  const initialTyping = Boolean(surveyUserAnswer?.text?.length);
  const [typing, setTyping] = useState<boolean>(initialTyping);

  const toggleMode = () => setTyping((prev) => !prev);

  const onRecordingStart = () => {
    if (surveyUserAnswer) {
      onDelete();
    }
  };

  const onRecordingComplete = (
    audio_file: Blob | undefined,
    audio_duration: number,
  ) => {
    if (disabled) return;

    if (audio_file) {
      onChange({ audio_file, audio_duration }, { useFormData: true });
    }
  };

  const getClassName = () => {
    const list = [
      'd-flex',
      'flex-column',
      'justify-content-center',
      'align-items-center',
    ];

    if (disabled) {
      list.push('opacity-75', 'pe-none');
    }

    return list.join(' ');
  };

  return (
    <div className={getClassName()}>
      {typing ? (
        <TextareaInput
          surveyUserAnswer={surveyUserAnswer}
          onChange={onChange}
          onDelete={onDelete}
          disabled={disabled}
          {...props}
        />
      ) : (
        <AudioRecorder
          uploading={updating}
          onRecordingStart={onRecordingStart}
          onRecordingComplete={onRecordingComplete}
          uploadedTrack={{ duration: surveyUserAnswer?.value?.duration }}
        />
      )}
      <button className='btn btn-link' onClick={toggleMode}>
        Prefer to {typing ? 'record' : 'type'}
      </button>
    </div>
  );
};

export default AudioInput;
