import React from 'react';
import { connect } from 'react-redux';
import { createOrUpdateAnswer } from '../../../../libs/redux/actions/userAnswerActions';
import { useQuizContext } from '../../../../libs/context/QuizProvider';
import { IOptions } from './types';
import ISurveyUserAnswer from '../../../../interfaces/ISurveyUserAnswer';
import * as questionTypes from '../../../../libs/helpers/questionTypeHelper';
import TextareaInput from './TextareaInput';
import RangeInput from './RangeInput';
import ChecklistInput from './ChecklistInput';
import RadioInput from './RadioInput';
import AudioInput from './AudioInput';
import TileInput from './TileInput';
import ValidationError from '../../../ValidationError';
import useQuestionForm from './useQuestionForm';
import RadioTextInput from './RadioTextInput';

const componentMap: any = {
  [questionTypes.TEXTAREA]: TextareaInput,
  [questionTypes.CHECKLIST]: ChecklistInput,
  [questionTypes.RADIO]: RadioInput,
  [questionTypes.AUDIO_OR_TEXT]: AudioInput,
  [questionTypes.TILE_PICKER]: TileInput,
  [questionTypes.RADIO_OR_TEXT]: RadioTextInput,
  default: RangeInput,
};

const validationMessages: Record<string, string> = {
  [questionTypes.TEXTAREA]: 'Please type your answer.',
  [questionTypes.CHECKLIST]: 'Please select at least one answer.',
  [questionTypes.AUDIO_OR_TEXT]:
    'Please record your response or type your answer.',
  default: 'Please select your answer.',
};

const QuestionForm: React.FC<any> = ({ showValidationError, ...props }) => {
  const { enqueue, isSubmitting } = useQuizContext();
  const {
    updating,
    questionKey,
    question,
    surveyUserAnswer,
    submitAnswer,
    removeAnswer,
  } = useQuestionForm({ enqueue, ...props });

  const { type } = question;
  const InputComponent = type
    ? componentMap[type.key] || componentMap.default
    : componentMap.default;

  const validationMessage = type?.key
    ? validationMessages[type.key] || validationMessages.default
    : validationMessages.default;

  const onChangeHandler = (
    values: Partial<ISurveyUserAnswer>,
    options: IOptions,
  ) => {
    if (!isSubmitting) {
      submitAnswer(values, options);
    }
  };

  const onDeleteHandler = () => {
    if (!isSubmitting) {
      removeAnswer();
    }
  };

  return (
    <React.Fragment>
      <InputComponent
        inputId={questionKey}
        surveyUserAnswer={surveyUserAnswer}
        question={question}
        onChange={onChangeHandler}
        onDelete={onDeleteHandler}
        disabled={isSubmitting}
        updating={updating}
        {...props}
      />
      {showValidationError && surveyUserAnswer === undefined && (
        <div className='validation-error'>
          <ValidationError error={validationMessage} />
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  createOrUpdateAnswer,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionForm);
