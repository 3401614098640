import React from 'react';
import TilePicker from '../../../tile-picker/TilePicker';
import { IOption } from '../../../range-slider/RangeSlider';
import { InputProps } from './types';
import { ReactComponent as OfficeIcon } from '../../../../assets/icons/office.svg';
import { ReactComponent as HybridIcon } from '../../../../assets/icons/hybrid.svg';
import { ReactComponent as RemoteIcon } from '../../../../assets/icons/remote.svg';

const iconMap: any = {
  'office-based': OfficeIcon,
  'hybrid': HybridIcon,
  'remote': RemoteIcon,
};

const TileInput: React.FC<InputProps> = ({
  question,
  surveyUserAnswer,
  onChange,
}) => {
  const questionAnswers = question.answers || [];
  const options: any = questionAnswers.map(({ id, name }) => {
    const Icon = name ? iconMap[name.toLowerCase()] : null;

    return {
      value: id,
      label: name,
      icon: Icon ? <Icon /> : null,
    };
  });

  const selectedOption = options.find(
    ({ value }: any) => surveyUserAnswer?.answer_id === value,
  );
  const setOption = ({ value }: IOption) =>{
    onChange({ answer_id: value });
  }

  return (
    <TilePicker
      selectedOption={selectedOption}
      setOption={setOption}
      options={options}
    />
  );
};

export default TileInput;
