import IQuizSurvey from '../../../interfaces/IQuizSurvey';

const useQuizSurveySettings = (quizSurvey: IQuizSurvey) => {
  const settings = quizSurvey.settings;

  const isSkippable = () => settings.is_skippable === 1;

  const isExclusive = (): boolean => settings?.exclusive ?? false;

  const isSkipOnEndVisible = (): boolean => isSkippable() && showSkipOnEnd();

  const showSkipOnEnd = (): boolean => settings?.skip_on_end ?? false;

  const canContinueLater = (currentStep: number) => {
    const { 'cl-enabled': enabled = false, 'cl-step': step = 0 } = settings;

    return enabled && currentStep >= step;
  };

  const hiddenOnSkipIds = (): string[] => settings?.hidden_on_skip_ids ?? [];

  const repeatForEachPartner = (): boolean => settings?.repeat_ep ?? false;

  const getIntroKey = (): string => settings?.introduction;

  const getPerPage = (): number => settings?.per_page ?? 0;

  const getScrollConfig = (): object => settings?.scroll_config ?? {};

  return {
    isSkippable,
    isExclusive,
    isSkipOnEndVisible,
    canContinueLater,
    hiddenOnSkipIds,
    repeatForEachPartner,
    getIntroKey,
    getPerPage,
    getScrollConfig,
  };
};

export default useQuizSurveySettings;
