import React from 'react';
// import { useSeededShuffle } from '../../../../libs/hooks/useSeededShuffle';
import { InputProps } from './types';
import IAnswer from '../../../../interfaces/IAnswer';
import RadioList from '../../../radio-list/RadioList';


const RadioTextInput: React.FC<InputProps> = ({
  inputId,
  question,
  surveyUserAnswer,
  onChange,
  onDelete,
  disabled,
}) => {
  const answers: IAnswer[] = question.answers || [];
  const typeAnswer = answers.at(-1);
  const typing = typeAnswer !== undefined;


  const options = answers
  .filter((item) => item !== typeAnswer)
  .map(({ id, name }: IAnswer) => ({
    label: name,
    value: id,
  }));

  // const randomizedOptions = useSeededShuffle(options, inputId);

  const selectedOption = options.find(
    (x) => surveyUserAnswer?.answer_id === x.value,
  );
  
  const finalSelectedOption  = selectedOption ?? 
  (surveyUserAnswer?.text ? {label: typeAnswer?.name ?? '', value: surveyUserAnswer.text} : undefined);

  const isInitialTypingAnswerSelected = !selectedOption && Boolean(finalSelectedOption);

  const changeHandler = (option: { value: any } | null) => {
    if (disabled || !option) return;
    
    if(!options.some(item => item.value === option.value)){
      option.value ? onChange({text: option.value }) : onDelete();
    }else {
        onChange({ answer_id: option.value });
    }
    
  };

  return (
    <RadioList
      name={`radio-${inputId}`}
      selectedOption={finalSelectedOption}
      options={options}
      typing={typing}
      isInitialTypingAnswerSelected ={isInitialTypingAnswerSelected}
      typingLabel={typeAnswer?.name}
      onChange={changeHandler}
      disabled={disabled}
      className='fs-14'
    />
  );
};

export default RadioTextInput;